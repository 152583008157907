<template>
  <div>signout...</div>
</template>
<script>
export default {
  computed: {},
  props: {},
  data: () => ({}),
  methods: {},
  watch: {},
  mounted() {
    // ログアウト
    this.post("auth/user/logout", {})
      .then(() => {
        //ユーザ情報削除
        this.$store.commit("clearUser");
      })
      .catch(() => {
        //ユーザ情報削除は失敗時でもやっておく(finaly?)
        this.$store.commit("clearUser");

        // FIXME とりあえずエラーは出さない
      })
      .finally(() => {

        // ログインに飛ばす
        this.$router.push("/Signin");
      });
  },
};
</script>
